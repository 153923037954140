<template>
  <div>
    <div class="overflow-x-hidden justify-between pt-16">
      <h1 class="font-bold text-4xl mb-6 text-center" id="about">
        {{ $t("pages.about.aboutTitle") }}
      </h1>
      <section class="text-gray-700 body-font">
        <div class="container px-5 pt-10 pb-24 mx-auto flex flex-col">
          <div class="lg:w-4/6 mx-auto">
            <div class="rounded-lg h-64 md:h-full overflow-hidden">
              <img
                class="object-cover object-center h-full w-full"
                src="@/assets/stav_photo.jpg"
                alt="Dr. Argyridou"
              />
            </div>
            <div class="flex flex-col sm:flex-row mt-10">
              <div class="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                <div
                  class="w-32 h-32 rounded-full inline-flex items-center justify-center"
                >
                  <img
                    src="@/assets/stav_photo_small.jpg"
                    alt="Dr. Argyridou"
                    class="w-full h-full rounded-full"
                  />
                </div>
                <div
                  class="flex flex-col items-center text-center justify-center"
                >
                  <h2 class="font-medium title-font mt-4 text-gray-900 text-lg">
                    {{ $t("pages.about.name") }}
                  </h2>
                  <div class="w-12 h-1 bg-green-500 rounded mt-2 mb-4"></div>
                  <h2 class="text-base text-gray-600">
                    {{ $t("pages.about.aboutsubTitle") }}
                  </h2>
                </div>
              </div>
              <div
                class="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-300 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left"
              >
                <p class="leading-relaxed text-base text-justify mb-4">
                  {{ $t("pages.about.aboutText_pt1") }}
                </p>
                <p class="leading-relaxed text-base text-justify mb-4">
                  {{ $t("pages.about.aboutText_pt2") }}
                </p>
                <p class="leading-relaxed text-base text-justify mb-4">
                  {{ $t("pages.about.aboutText_pt3") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    var locale = this.$i18n.locale;
    return {
      title: locale == "gr" ? "Σχετικά | MedInDiet" : "About | MedInDiet",
      meta:
        locale == "gr"
          ? [
              {
                name: "description",
                content:
                  "Δρ. Αργυρίδου Σταυρούλα, Εγγεγραμμένη Κλινική Διαιτολόγος- Διατροφολόγος. Εξειδίκευση στην πρόληψη του Διαβήτη Τύπου ΙΙ & των Καρδιαγγειακών Νοσημάτων",
              },
              {
                name: "keywords",
                content:
                  "Διαιτολόγος, Διαιτολόγος Κύπρος, Διαιτολόγος Λευκωσία, Διατροφολόγος Κύπρος, Διατροφολόγος Λευκωσία",
              },
            ]
          : [
              {
                name: "description",
                content:
                  "MedInDiet: Dr. Argyridou Stavroula, Registered Clinical Dietitian-Nutritionist Specialist in Type II Diabetes & Cardiovascular Disease Prevention",
              },
              {
                name: "keywords",
                content: "Dietitian, Dietitian Cyprus, Dietitian Nicosia",
              },
            ],
      link: [
        { rel: "canonical", href: "https://medindiet.com/gr/about" },
        {
          rel: "alternate",
          hreflang: "el",
          href: "https://medindiet.com/gr/about",
        },
        {
          rel: "alternate",
          hreflang: "en",
          href: "https://medindiet.com/en/about",
        },
      ],
    };
  },
};
</script>
